import { createSelector } from "reselect";

const selectProperties = (state) => state.properties;

export const selectPropertiesList = createSelector(
  [selectProperties],
  (properties) => properties.propertiesList
);

export const selectCurrentProperty = createSelector(
  [selectProperties],
  (properties) => properties.currentProperty
);

export const selectListOfStates = createSelector(
  [selectProperties],
  (properties) => properties.states
);

export const selectListOfCities = createSelector(
  [selectProperties],
  (properties) => properties.cities
);

export const selectListOfTypesOfProperties = createSelector(
  [selectProperties],
  (properties) => properties.types
);
