export const getSet = (list) => {
  const listSet = new Set();
  const listLength = list.length;

  for (let i = 0; i < listLength; i++) {
    listSet.add(list[i]);
  }

  return [...listSet];
};
