import { takeLatest, put, all, call } from "redux-saga/effects";
import { PropertyActionTypes } from "./properties.action-types";
import {
  getListOfProperties,
  getProperty,
} from "../../firebase/firebase.utils";
import {
  getPropertyListSuccess,
  getPropertyListFailure,
  getPropertySuccess,
  getPropertyFailure,
  /*setPropertySuccess,
  setPropertyFailure,*/
} from "./properties.actions";

function* getPropertyData({ payload }) {
  try {
    const property = yield getProperty(payload);
    if (!!property) {
      yield put(getPropertySuccess(property));
    } else {
      yield put(getPropertyFailure("property doesn't exist"));
    }
  } catch (error) {
    yield put(getPropertyFailure(error));
  }
}

export function* onGetPropertyStart() {
  yield takeLatest(PropertyActionTypes.GET_PROPERTY_START, getPropertyData);
}

function* getPropertyList() {
  try {
    const list = yield getListOfProperties();
    yield put(getPropertyListSuccess(list));
  } catch (error) {
    yield put(getPropertyListFailure(error));
  }
}

export function* onGetPropertyListStart() {
  yield takeLatest(
    PropertyActionTypes.GET_LIST_OF_PROPERTIES_START,
    getPropertyList
  );
}

export function* propertiesSagas() {
  yield all([call(onGetPropertyListStart), call(onGetPropertyStart)]);
}

/*export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(
      createUserProfileDocument,
      userAuth,
      additionalData
    );
    const userSnapshot = yield userRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup(googleProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signOut() {
  try {
    yield auth.signOut();
    yield put(signOutSuccess());
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

export function* signUp({ payload: { email, password, displayName } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    yield put(signUpSuccess({ user, additionalData: { displayName } }));
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
  ]);
}

*/
