import { takeLatest, put, all, call } from "redux-saga/effects";
import { DraftActionTypes } from "./drafts.action-types";
import {
  saveDraft,
  submitDraft,
  approveDraft,
  rejectDraft,
  readDrafts,
  readRejects,
  readTemps,
  readUsers,
  moveToDraft,
} from "./drafts.utils";

import {
  saveDraftSuccess,
  saveDraftFailure,
  submitDraftSuccess,
  submitDraftFailure,
  approveDraftSuccess,
  approveDraftFailure,
  rejectDraftSuccess,
  rejectDraftFailure,
  getUsersSuccess,
  getUsersFailure,
  getDraftsSuccess,
  getDraftsFailure,
  getTempsSuccess,
  getTempsFailure,
  getRejectsSuccess,
  getRejectsFailure,
  moveToDraftsSuccess,
  moveToDraftsFailure,
} from "./drafts.actions";

function* moveToDraftSaga({ payload }) {
  try {
    const status = yield moveToDraft(payload.property, payload.user);
    yield put(moveToDraftsSuccess(status));
  } catch (error) {
    yield put(moveToDraftsFailure(error));
  }
}

export function* onMoveToDraftStart() {
  yield takeLatest(DraftActionTypes.MOVE_TO_DRAFTS_START, moveToDraftSaga);
}

function* getTempsSaga() {
  try {
    const list = yield readTemps();
    yield put(getTempsSuccess(list));
  } catch (error) {
    yield put(getTempsFailure(error));
  }
}

export function* onGetTempsStart() {
  yield takeLatest(DraftActionTypes.GET_TEMPS_START, getTempsSaga);
}

function* getRejectsSaga({ payload }) {
  try {
    const list = yield readRejects(payload);
    yield put(getRejectsSuccess(list));
  } catch (error) {
    yield put(getRejectsFailure(error));
  }
}

export function* onGetRejectsStart() {
  yield takeLatest(DraftActionTypes.GET_REJECTS_START, getRejectsSaga);
}

function* getDraftsSaga({ payload }) {
  try {
    const list = yield readDrafts(payload);
    yield put(getDraftsSuccess(list));
  } catch (error) {
    yield put(getDraftsFailure(error));
  }
}

export function* onGetDraftsStart() {
  yield takeLatest(DraftActionTypes.GET_DRAFTS_START, getDraftsSaga);
}

function* getUsersSaga() {
  try {
    const list = yield readUsers();
    yield put(getUsersSuccess(list));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

export function* onGetUsersStart() {
  yield takeLatest(DraftActionTypes.GET_USERS_START, getUsersSaga);
}

function* rejectDraftSaga({ payload }) {
  try {
    const status = yield rejectDraft(
      payload.property,
      payload.editUser,
      payload.user,
      payload.remarks
    );
    yield put(rejectDraftSuccess(status));
  } catch (error) {
    yield put(rejectDraftFailure(error));
  }
}

export function* onRejectDraftStart() {
  yield takeLatest(DraftActionTypes.REJECT_DRAFT_START, rejectDraftSaga);
}

function* approveDraftSaga({ payload }) {
  try {
    const status = yield approveDraft(
      payload.property,
      payload.editUser,
      payload.user
    );
    yield put(approveDraftSuccess(status));
  } catch (error) {
    yield put(approveDraftFailure(error));
  }
}

export function* onApproveDraftStart() {
  yield takeLatest(DraftActionTypes.APPROVE_DRAFT_START, approveDraftSaga);
}

function* submitDraftSaga({ payload }) {
  try {
    const status = yield submitDraft(payload.property, payload.user);
    yield put(submitDraftSuccess(status));
  } catch (error) {
    yield put(submitDraftFailure(error));
  }
}

export function* onSubmitDraftStart() {
  yield takeLatest(DraftActionTypes.SUBMIT_DRAFT_START, submitDraftSaga);
}

function* saveDraftSaga({ payload }) {
  try {
    const status = yield saveDraft(payload.property, payload.user);
    yield put(saveDraftSuccess(status));
  } catch (error) {
    yield put(saveDraftFailure(error));
  }
}

export function* onSaveDraftStart() {
  yield takeLatest(DraftActionTypes.SAVE_DRAFT_START, saveDraftSaga);
}

export function* draftSagas() {
  yield all([
    call(onSaveDraftStart),
    call(onSubmitDraftStart),
    call(onApproveDraftStart),
    call(onRejectDraftStart),
    call(onGetUsersStart),
    call(onGetDraftsStart),
    call(onGetRejectsStart),
    call(onGetTempsStart),
    call(onMoveToDraftStart),
  ]);
}
