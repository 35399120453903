import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 2px solid white;
  padding: 10px 60px;
  text-align: center;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItem = styled.div`
  margin-right: 25px;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 2px solid #ff6c6b;
  padding-bottom: 5px;
`;
