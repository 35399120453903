import { DraftActionTypes } from "./drafts.action-types";

/*

0: idle,
1: in process,
2: success,
3: failure

*/

const INITIAL_STATE = {
  draftSave: 0,
  draftSubmit: 0,
  tempApprove: 0,
  tempReject: 0,
  moveDraft: 0,
  usersList: [],
  draftsList: [],
  tempsList: [],
  rejectsList: [],
};

const draftsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DraftActionTypes.SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        draftSave: 2,
      };
    case DraftActionTypes.SAVE_DRAFT_FAILURE:
      return {
        ...state,
        draftSave: 3,
      };
    case DraftActionTypes.SUBMIT_DRAFT_SUCCESS:
      return {
        ...state,
        draftSubmit: 2,
      };
    case DraftActionTypes.SUBMIT_DRAFT_FAILURE:
      return {
        ...state,
        draftSubmit: 3,
      };
    case DraftActionTypes.APPROVE_DRAFT_SUCCESS:
      return {
        ...state,
        tempApprove: 2,
      };
    case DraftActionTypes.APPROVE_DRAFT_FAILURE:
      return {
        ...state,
        tempApprove: 3,
      };
    case DraftActionTypes.APPROVE_REJECT_SUCCESS:
      return {
        ...state,
        tempReject: 2,
      };
    case DraftActionTypes.APPROVE_REJECT_FAILURE:
      return {
        ...state,
        tempReject: 3,
      };
    case DraftActionTypes.MOVE_TO_DRAFTS_SUCCESS:
      return {
        ...state,
        moveDraft: 2,
      };
    case DraftActionTypes.MOVE_TO_DRAFTS_FAILURE:
      return {
        ...state,
        moveDraft: 3,
      };
    case DraftActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
      };
    case DraftActionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        usersList: [],
      };
    case DraftActionTypes.GET_DRAFTS_SUCCESS:
      return {
        ...state,
        draftsList: action.payload,
      };
    case DraftActionTypes.GET_DRAFTS_FAILURE:
      return {
        ...state,
        draftsList: [],
      };
    case DraftActionTypes.GET_TEMPS_SUCCESS:
      return {
        ...state,
        tempsList: action.payload,
      };
    case DraftActionTypes.GET_TEMPS_FAILURE:
      return {
        ...state,
        tempsList: [],
      };
    case DraftActionTypes.GET_REJECTS_SUCCESS:
      return {
        ...state,
        rejectsList: action.payload,
      };
    case DraftActionTypes.GET_REJECTS_FAILURE:
      return {
        ...state,
        rejectsList: [],
      };
    default:
      return state;
  }
};

export default draftsReducer;
