export const DraftActionTypes = {
  SAVE_DRAFT_START: "SAVE_DRAFT_START",
  SAVE_DRAFT_SUCCESS: "SAVE_DRAFT_SUCCESS",
  SAVE_DRAFT_FAILURE: "SAVE_DRAFT_FAILURE",
  SUBMIT_DRAFT_START: "SUBMIT_DRAFT_START",
  SUBMIT_DRAFT_SUCCESS: "SUBMIT_DRAFT_SUCCESS",
  SUBMIT_DRAFT_FAILURE: "SUBMIT_DRAFT_FAILURE",
  APPROVE_DRAFT_START: "APPROVE_DRAFT_START",
  APPROVE_DRAFT_SUCCESS: "APPROVE_DRAFT_SUCCESS",
  APPROVE_DRAFT_FAILURE: "APPROVE_DRAFT_FAILURE",
  REJECT_DRAFT_START: "REJECT_DRAFT_START",
  REJECT_DRAFT_SUCCESS: "REJECT_DRAFT_SUCCESS",
  REJECT_DRAFT_FAILURE: "REJECT_DRAFT_FAILURE",
  GET_USER_DETAILS_START: "GET_USER_DETAILS_START",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",
  GET_USERS_START: "GET_USERS_START",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_DRAFTS_START: "GET_DRAFTS_START",
  GET_DRAFTS_SUCCESS: "GET_DRAFTS_SUCCESS",
  GET_DRAFTS_FAILURE: "GET_DRAFTS_FAILURE",
  GET_TEMPS_START: "GET_TEMPS_START",
  GET_TEMPS_SUCCESS: "GET_TEMPS_SUCCESS",
  GET_TEMPS_FAILURE: "GET_TEMPS_FAILURE",
  GET_REJECTS_START: "GET_REJECTS_START",
  GET_REJECTS_SUCCESS: "GET_REJECTS_SUCCESS",
  GET_REJECTS_FAILURE: "GET_REJECTS_FAILURE",
  MOVE_TO_DRAFTS_START: "MOVE_TO_DRAFTS_START",
  MOVE_TO_DRAFTS_SUCCESS: "MOVE_TO_DRAFTS_SUCCESS",
  MOVE_TO_DRAFTS_FAILURE: "MOVE_TO_DRAFTS_FAILURE",
};
