import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyBpW7fr5rGDf5G9b9AY8Yv5rcPVfyWNDhM",
  authDomain: "e-commerce-2692f.firebaseapp.com",
  databaseURL: "https://e-commerce-2692f.firebaseio.com",
  projectId: "e-commerce-2692f",
  storageBucket: "e-commerce-2692f.appspot.com",
  messagingSenderId: "481650315804",
  appId: "1:481650315804:web:5cfd0a7267147a949b05d9",
  measurementId: "G-FY8QL36CMW",
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`usersOps/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.log("error creating user", error.message);
    }
  }

  return userRef;
};

firebase.initializeApp(config);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export const getListOfProperties = async () => {
  const ref = firestore.doc(`propertiesListBackend/data`);

  const list = await ref
    .get()
    .then((snapshot) => snapshot.data())
    .then((data) =>
      data.data.map((item) =>
        !!item.tags ? item : { ...item, tags: [false, false, false] }
      )
    );

  return list.map((item) => {
    let tagsList = [];
    //
    if (item.tags[0]) {
      tagsList.push("Prime");
    }
    if (item.tags[1]) {
      tagsList.push("Workcations Assured");
    }
    if (item.tags[2]) {
      tagsList.push("Staycations");
    }

    return {
      id: item.slug.split("-")[0],
      title: item.title,
      slug: item.slug,
      name: item.name ?? "",
      city: item.location.city.toLowerCase(),
      state: item.location.state.toLowerCase(),
      type: item.type.toLowerCase(),
      visibility: item.visibility,
      tags: item.tags,
      tagsList,
      poc: {
        phone: !!item.poc ? item.poc.phone ?? "" : "",
        name: !!item.poc ? item.poc.name ?? "" : "",
      },
      pricingUpdate: item.pricingUpdate,
    };
  });
};

export const getProperty = async (slug) => {
  const ref = firestore.doc(`propertiesData/${slug.split("-")[0]}`);

  const snapshot = await ref.get();

  if (!snapshot.exists) {
    return false;
  }

  const property = snapshot.data();

  return property;
};

export default firebase;
