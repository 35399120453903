import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import propertiesReducer from "./properties/properties.reducer";
import draftsReducer from "./drafts/drafts.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    /*"properties",
    "drafts",*/
    /*"cart"*/
  ], //not adding user bcz firestore handles it for us
};

const rootReducer = combineReducers({
  user: userReducer,
  properties: propertiesReducer,
  drafts: draftsReducer,
});

export default persistReducer(persistConfig, rootReducer);

/*

arun.rishiraj - Mar 8 -7:40pm
gaurav.rastogi1 - Mar 7 - 11:22pm
sandeep.khajuria - Mar 5 - 8:23pm
SUMIT.KAUSHAL - Mar 5 - 5:33pm
deepak.mathur - Mar 5 - 5:21pm
dalalmanjunath.d - Mar 5 - 5:19pm
harpreet.sharma1 - Mar 5 - 5:04pm
manu.sharma3 - Mar 5 - 4:57pm
sudhir.raj - Mar 5 - 4:45pm
madhu.joseph - Feb 29 - 9:42pm
rajeev.nair - Feb 27 - 11:17am
arun.rishiraj - Feb 26 - 9:21pm

*/
