import axios from "axios";

export const saveDraft = async (property, user) => {
  const data = JSON.stringify({
    property: property,
    user: user,
  });

  const config = {
    method: "post",
    url: "https://api.wanderon.in/saveDraft",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const submitDraft = async (property, user) => {
  const data = JSON.stringify({
    property: property,
    user: user,
  });

  const config = {
    method: "post",
    url: "https://api.wanderon.in/submitDraft",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const approveDraft = async (property, editUser, user) => {
  const data = JSON.stringify({
    property: property,
    editUser: editUser,
    user: user,
  });

  const config = {
    method: "post",
    url: "https://api.wanderon.in/approveDraft",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const rejectDraft = async (property, editUser, user, remarks) => {
  const data = JSON.stringify({
    property: property,
    editUser: editUser,
    user: user,
    remarks: remarks,
  });

  const config = {
    method: "post",
    url: "https://api.wanderon.in/rejectDraft",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const readDrafts = async (userId) => {
  const config = {
    method: "get",
    url: `https://api.wanderon.in/drafts/${userId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const readRejects = async (userId) => {
  const config = {
    method: "get",
    url: `https://api.wanderon.in/rejects/${userId}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const readTemps = async () => {
  const config = {
    method: "get",
    url: `https://api.wanderon.in/temps`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const readUsers = async () => {
  const config = {
    method: "get",
    url: `https://api.wanderon.in/getUsers`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const moveToDraft = async (property, user) => {
  const data = JSON.stringify({
    property: property,
    user: user,
  });

  const config = {
    method: "post",
    url: "https://api.wanderon.in/rejectToDraft",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const tempToDraft = async (property, user) => {
  const data = JSON.stringify({
    property: property,
    user: user,
  });

  const config = {
    method: "post",
    url: "https://api.wanderon.in/tempToDraft",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};
