export const PropertyActionTypes = {
  GET_LIST_OF_PROPERTIES_START: "GET_LIST_OF_PROPERTIES_START",
  GET_LIST_OF_PROPERTIES_SUCCESS: "GET_LIST_OF_PROPERTIES_SUCCESS",
  GET_LIST_OF_PROPERTIES_FAILURE: "GET_LIST_OF_PROPERTIES_FAILURE",
  GET_PROPERTY_START: "GET_PROPERTY_START",
  GET_PROPERTY_SUCCESS: "GET_PROPERTY_SUCCESS",
  GET_PROPERTY_FAILURE: "GET_PROPERTY_FAILURE",
  SET_PROPERTY_START: "SET_PROPERTY_START",
  SET_PROPERTY_SUCCESS: "SET_PROPERTY_SUCCESS",
  SET_PROPERTY_FAILURE: "SET_PROPERTY_FAILURE",
  UPDATE_PROPERTY: "UPDATE_PROPERTY",
  GET_LIST_OF_STATES: "GET_LIST_OF_STATES",
  GET_LIST_OF_CITIES: "GET_LIST_OF_CITIES",
  GET_LIST_OF_TYPE_OF_PROPERTIES: "GET_LIST_OF_TYPE_OF_PROPERTIES",
};
