import { PropertyActionTypes } from "./properties.action-types";

export const getPropertyListStart = () => ({
  type: PropertyActionTypes.GET_LIST_OF_PROPERTIES_START,
});

export const getPropertyListSuccess = (list) => ({
  type: PropertyActionTypes.GET_LIST_OF_PROPERTIES_SUCCESS,
  payload: list,
});

export const getPropertyListFailure = (error) => ({
  type: PropertyActionTypes.GET_LIST_OF_PROPERTIES_FAILURE,
  payload: error,
});

export const getPropertyStart = (slug) => ({
  type: PropertyActionTypes.GET_PROPERTY_START,
  payload: slug,
});

export const getPropertySuccess = (property) => ({
  type: PropertyActionTypes.GET_PROPERTY_SUCCESS,
  payload: property,
});

export const getPropertyFailure = (error) => ({
  type: PropertyActionTypes.GET_PROPERTY_FAILURE,
  payload: error,
});

export const setPropertyStart = () => ({
  type: PropertyActionTypes.SET_PROPERTY_START,
});

export const setPropertySuccess = (property) => ({
  type: PropertyActionTypes.SET_PROPERTY_SUCCESS,
  payload: property,
});

export const setPropertyFailure = (error) => ({
  type: PropertyActionTypes.SET_PROPERTY_FAILURE,
  payload: error,
});

export const getListOfStates = () => ({
  type: PropertyActionTypes.GET_LIST_OF_STATES,
});

export const getListOfCities = () => ({
  type: PropertyActionTypes.GET_LIST_OF_CITIES,
});

export const getListOfTypeOfProperties = () => ({
  type: PropertyActionTypes.GET_LIST_OF_TYPE_OF_PROPERTIES,
});

export const updateProperty = (property) => ({
  type: PropertyActionTypes.UPDATE_PROPERTY,
  payload: property,
});
