import { PropertyActionTypes } from "./properties.action-types";

import { getSet } from "./properties.utils";

const INITIAL_STATE = {
  propertiesList: [],
  currentProperty: null,
  states: [],
  cities: [],
  types: [],
  error: null,
};

const propertyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PropertyActionTypes.GET_LIST_OF_PROPERTIES_SUCCESS:
      return {
        ...state,
        propertiesList: action.payload,
      };
    case PropertyActionTypes.GET_PROPERTY_SUCCESS:
      return {
        ...state,
        currentProperty: action.payload,
      };
    case PropertyActionTypes.UPDATE_PROPERTY:
      return {
        ...state,
        currentProperty: action.payload,
      };
    case PropertyActionTypes.SET_PROPERTY_SUCCESS:
      return {
        ...state,
        currentProperty: action.payload,
      };
    case PropertyActionTypes.GET_LIST_OF_STATES: {
      return {
        ...state,
        states: getSet(state.propertiesList.map((item) => item.state)),
      };
    }
    case PropertyActionTypes.GET_LIST_OF_CITIES: {
      return {
        ...state,
        cities: getSet(state.propertiesList.map((item) => item.city)),
      };
    }
    case PropertyActionTypes.GET_LIST_OF_TYPE_OF_PROPERTIES: {
      return {
        ...state,
        types: getSet(state.propertiesList.map((item) => item.type)),
      };
    }
    case PropertyActionTypes.GET_LIST_OF_PROPERTIES_FAILURE:
    case PropertyActionTypes.GET_PROPERTY_FAILURE:
    case PropertyActionTypes.SET_PROPERTY_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default propertyReducer;
