import { createSelector } from "reselect";

const selectDrafts = (state) => state.drafts;

export const selectDraftSaveStatus = createSelector(
  [selectDrafts],
  (drafts) => drafts.draftSave
);

export const selectDraftSubmitStatus = createSelector(
  [selectDrafts],
  (drafts) => drafts.draftSubmit
);

export const selectTempApproveStatus = createSelector(
  [selectDrafts],
  (drafts) => drafts.tempApprove
);

export const selectTempRejectStatus = createSelector(
  [selectDrafts],
  (drafts) => drafts.tempReject
);

export const selectMoveToDraftStatus = createSelector(
  [selectDrafts],
  (drafts) => drafts.moveDraft
);

export const selectUsersList = createSelector(
  [selectDrafts],
  (drafts) => drafts.usersList
);

export const selectDraftsList = createSelector(
  [selectDrafts],
  (drafts) => drafts.draftsList
);

export const selectTempsList = createSelector(
  [selectDrafts],
  (drafts) => drafts.tempsList
);

export const selectRejectsList = createSelector(
  [selectDrafts],
  (drafts) => drafts.rejectsList
);
