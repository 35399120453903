import { DraftActionTypes } from "./drafts.action-types";

export const saveDraftStart = (propertyAndUser) => ({
  type: DraftActionTypes.SAVE_DRAFT_START,
  payload: propertyAndUser,
});

export const saveDraftSuccess = () => ({
  type: DraftActionTypes.SAVE_DRAFT_SUCCESS,
});

export const saveDraftFailure = (error) => ({
  type: DraftActionTypes.SAVE_DRAFT_FAILURE,
  payload: error,
});

export const submitDraftStart = (propertyAndUser) => ({
  type: DraftActionTypes.SUBMIT_DRAFT_START,
  payload: propertyAndUser,
});

export const submitDraftSuccess = (ref) => ({
  type: DraftActionTypes.SUBMIT_DRAFT_SUCCESS,
  payload: ref,
});

export const submitDraftFailure = (error) => ({
  type: DraftActionTypes.SUBMIT_DRAFT_FAILURE,
  payload: error,
});

export const approveDraftStart = (propertyAndUsers) => ({
  type: DraftActionTypes.APPROVE_DRAFT_START,
  payload: propertyAndUsers,
});

export const approveDraftSuccess = (ref) => ({
  type: DraftActionTypes.APPROVE_DRAFT_SUCCESS,
  payload: ref,
});

export const approveDraftFailure = (error) => ({
  type: DraftActionTypes.APPROVE_DRAFT_FAILURE,
  payload: error,
});

export const rejectDraftStart = (propertyAndUsers) => ({
  type: DraftActionTypes.REJECT_DRAFT_START,
  payload: propertyAndUsers,
});

export const rejectDraftSuccess = (ref) => ({
  type: DraftActionTypes.REJECT_DRAFT_SUCCESS,
  payload: ref,
});

export const rejectDraftFailure = (error) => ({
  type: DraftActionTypes.REJECT_DRAFT_FAILURE,
  payload: error,
});

export const getUserDetailsStart = (id) => ({
  type: DraftActionTypes.GET_USER_DETAILS_START,
  payload: id,
});

export const getUserDetailsSuccess = (user) => ({
  type: DraftActionTypes.GET_USER_DETAILS_SUCCESS,
  payload: user,
});

export const getUserDetailsFailure = (error) => ({
  type: DraftActionTypes.GET_USER_DETAILS_FAILURE,
  payload: error,
});

export const getUsersStart = () => ({
  type: DraftActionTypes.GET_USERS_START,
});

export const getUsersSuccess = (usersList) => ({
  type: DraftActionTypes.GET_USERS_SUCCESS,
  payload: usersList,
});

export const getUsersFailure = (error) => ({
  type: DraftActionTypes.GET_USERS_FAILURE,
  payload: error,
});

export const getDraftsStart = (userId) => ({
  type: DraftActionTypes.GET_DRAFTS_START,
  payload: userId,
});

export const getDraftsSuccess = (draftsList) => ({
  type: DraftActionTypes.GET_DRAFTS_SUCCESS,
  payload: draftsList,
});

export const getDraftsFailure = (error) => ({
  type: DraftActionTypes.GET_DRAFTS_FAILURE,
  payload: error,
});

export const getTempsStart = () => ({
  type: DraftActionTypes.GET_TEMPS_START,
});

export const getTempsSuccess = (tempsList) => ({
  type: DraftActionTypes.GET_TEMPS_SUCCESS,
  payload: tempsList,
});

export const getTempsFailure = (error) => ({
  type: DraftActionTypes.GET_TEMPS_FAILURE,
  payload: error,
});

export const getRejectsStart = (userId) => ({
  type: DraftActionTypes.GET_REJECTS_START,
  payload: userId,
});

export const getRejectsSuccess = (rejectsList) => ({
  type: DraftActionTypes.GET_REJECTS_SUCCESS,
  payload: rejectsList,
});

export const getRejectsFailure = (error) => ({
  type: DraftActionTypes.GET_REJECTS_FAILURE,
  payload: error,
});

export const moveToDraftsStart = (propertyAndUser) => ({
  type: DraftActionTypes.MOVE_TO_DRAFTS_START,
  payload: propertyAndUser,
});

export const moveToDraftsSuccess = (ref) => ({
  type: DraftActionTypes.MOVE_TO_DRAFTS_SUCCESS,
  payload: ref,
});

export const moveToDraftsFailure = (error) => ({
  type: DraftActionTypes.MOVE_TO_DRAFTS_SUCCESS,
  payload: error,
});
