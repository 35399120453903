import React, { Fragment, useEffect, useState, lazy, Suspense } from "react";
import "./App.css";
import "./theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { selectCurrentUser } from "./redux/user/user.selectors";
import { checkUserSession } from "./redux/user/user.actions";
import { selectPropertiesList } from "./redux/properties/properties.selectors";
import { getPropertyListStart } from "./redux/properties/properties.actions";

import {
  getDraftsStart,
  getRejectsStart,
  getTempsStart,
  getUsersStart,
} from "./redux/drafts/drafts.actions";

import {
  selectUsersList,
  selectDraftsList,
  selectTempsList,
  selectRejectsList,
} from "./redux/drafts/drafts.selectors";

import Spinner from "./components/spinner/spinner";
import Header from "./components/header/header";

const HomePage = lazy(() => import("./pages/homepage/homepage"));
const LoginPage = lazy(() => import("./pages/login/login"));
const EditPage = lazy(() => import("./pages/edit/edit"));
const DraftsPage = lazy(() => import("./pages/drafts/drafts"));
const RejectsPage = lazy(() => import("./pages/rejects/rejects"));
const PendingPage = lazy(() => import("./pages/pending/pending"));
const AddPropertyPage = lazy(() => import("./pages/add-property/add-property"));

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [maintenance, setMaintenance] = useState(false);

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  const currentUserObject = useSelector(selectCurrentUser);

  const propertiesList = useSelector(selectPropertiesList);

  useEffect(() => {
    if (!!currentUserObject && propertiesList.length === 0) {
      dispatch(getPropertyListStart());
    }
  }, [propertiesList, currentUserObject, dispatch]);

  const usersList = useSelector(selectUsersList);
  const draftsList = useSelector(selectDraftsList);
  const rejectsList = useSelector(selectRejectsList);
  const tempsList = useSelector(selectTempsList);

  useEffect(() => {
    if (!!currentUserObject) {
      if (currentUserObject.access < 2) {
        dispatch(getUsersStart());
      }
      dispatch(getTempsStart());
      dispatch(getDraftsStart(currentUserObject.id));
      dispatch(getRejectsStart(currentUserObject.id));
    } else {
      const timer = setTimeout(function () {
        history.push("/login");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [currentUserObject]);

  return (
    <Fragment>
      {maintenance ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "30px",
            textAlign: "center",
          }}
        >
          Closed for Scheduled Maintenance!!
          <br />
          <br />
          Sorry for the incovenience!!
          <br />
          <br />
          Please check back after some time!!
        </div>
      ) : (
        <Fragment>
          <Header
            draftsList={draftsList}
            usersList={usersList}
            rejectsList={rejectsList}
            tempsList={tempsList}
            currentUserObject={currentUserObject}
          />
          <Switch>
            <Suspense fallback={<Spinner />}>
              <Route
                exact
                path="/"
                render={() =>
                  !!currentUserObject ? (
                    <HomePage properties={propertiesList} link="edit" />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/login"
                render={() =>
                  !!currentUserObject ? <Redirect to="/" /> : <LoginPage />
                }
              />
              <Route path="/edit" component={EditPage} />
              <Route path="/drafts" component={DraftsPage} />
              <Route path="/rejects" component={RejectsPage} />
              <Route path="/pending" component={PendingPage} />
              <Route path="/add" component={AddPropertyPage} />
            </Suspense>
          </Switch>
        </Fragment>
      )}
    </Fragment>
  );
};

export default App;
