import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { signOutStart } from "../../redux/user/user.actions";

import { Container, MenuContainer, MenuItem } from "./header.styles";

const menuItemsList = [
  {
    title: "List Of Properties",
    link: "",
  },
  /*{
    title: "Add Property",
    link: "add",
  },*/
  {
    title: "View My Drafts",
    link: "drafts",
  },
  {
    title: "Pending Changes",
    link: "pending",
  },
  {
    title: "Rejected Changes",
    link: "rejects",
  },
];

const Header = ({
  draftsList,
  usersList,
  rejectsList,
  tempsList,
  currentUserObject,
}) => {
  const dispatch = useDispatch();

  let newTempsList = [];

  for (let i = 0; i < tempsList.length; i++) {
    for (let j = 0; j < tempsList[i].temps.length; j++) {
      newTempsList.push({
        data: tempsList[i].temps[j],
        user: tempsList[i].user,
      });
    }
  }

  const numbers = [
    0,
    /*0,*/
    draftsList.length,
    !!currentUserObject
      ? currentUserObject.access < 2
        ? newTempsList.length
        : newTempsList.filter((item) => item.user.id === currentUserObject.id)
            .length
      : 0,
    rejectsList.length,
  ];

  const [menuItems, setMenuItems] = useState(menuItemsList);

  return (
    <Container>
      <MenuContainer>
        {menuItems.map((item, i) => (
          <a
            style={{ color: "#efefef" }}
            key={`Menu Item ${i + 1}`}
            href={`/${item.link}`}
          >
            <MenuItem>
              {item.title} {numbers[i] === 0 ? null : `(${numbers[i]})`}
            </MenuItem>
          </a>
        ))}
      </MenuContainer>
      <div
        onClick={() => {
          dispatch(signOutStart());
        }}
      >
        Sign Out
      </div>
    </Container>
  );
};

export default Header;
